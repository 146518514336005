import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "background", "content" ]

  toggle() {
    this.backgroundTarget.classList.toggle('show')
    this.toggleFade()
  }

  toggleFade() {
    if (this.contentTarget.classList.contains('fade-in')){
      this.contentTarget.classList.toggle('fade-in')
      this.timeout = setTimeout(() => this.contentTarget.classList.toggle('show'), 300)
    } else {
      this.contentTarget.classList.toggle('show')
      this.timeout = setTimeout(() => this.contentTarget.classList.toggle('fade-in'), 150)
    }
  }

  disconnect() {
    this.backgroundTarget.classList.remove('show')
    this.contentTarget.classList.remove('show', 'fade-in')
    clearTimeout(this.timeout)
  }
}
