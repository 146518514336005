import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "item" ]

  toggle(event) {
    event.preventDefault()
    this.itemTarget.classList.toggle('show')
  }

  hide(event) {
    if(this.element === event.target || this.element.contains(event.target)) return
    this.itemTarget.classList.remove('show')
  }
}
