import consumer from "./consumer"

class ChannelBase {
  constructor(channelName) {
    // Can't use class name as channel, because JS minifier changes class names
    this.channelName = channelName
    const selector = '[data-channel-name="' + this.channelName + '"]'
    this.element = document.querySelector(selector)

    if (this.element) {
      console.log("ChannelBase#subscribe")
      this.subscribe()
    } else {
      console.log("ChannelBase#unsubscribe")
      this.unsubscribe()
    }
  }

  identifier() {
    return { channel: this.channelName, key: this.key() }
  }

  subscribe() {
    if (this.hasSubscription()) return

    const channel = this
    consumer.subscriptions.create(this.identifier(), {
      connected() {
        // Called when the subscription is ready for use on the server
        channel.connected()
      },
      disconnected() {
        // Called when the subscription has been terminated by the server
      },
      received(data) {
        channel.received(data)
      }
    })
  }

  unsubscribe() {
    if (!this.hasSubscription()) return
    consumer.subscriptions.remove(consumer.subscriptions.subscriptions[0])
    console.log("Unsubscribed from " + this.channelName)
  }

  hasSubscription() {
    return consumer.subscriptions.subscriptions.length > 0
  }

  key() {
    return this.element.dataset.channelKey
  }

  connected() {
    console.log("Connected to the " + this.channelName + " with key:" + this.key())
    // console.log(consumer.subscriptions.subscriptions[0])
  }

  received(data) {
    console.log(data)
  }
}

export default ChannelBase
