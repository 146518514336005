import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "price", "variant", "upcharge" ]
  static values = { basePrice: Number, upcharges: Object, variants: Object, locale: String, unit: String }

  connect() {
    this.updatePrice()
  }

  updatePrice() {
    this.priceTarget.textContent = this.localize(this.total)
  }

  get total() {
    return (this.basePrice + this.upcharges).toFixed(2)
  }

  get upcharges() {
    let upcharges = 0.0

    this.upchargeTargets
      .filter((item) => item.checked)
      .forEach((item) => {
        const upcharge = Number(this.upchargesValue[item.value])
        upcharges += upcharge
      })

    return upcharges
  }

  get basePrice() {
    const variant = this.variantTargets.find((item) => item.checked)

    if (variant) {
      return Number(this.variantsValue[variant.value])
    } else {
      return this.basePriceValue
    }
  }

  localize(number) {
    if (this.localeValue.substring(0, 2) == "de") {
      return number.replace(".", ",").concat(" ", this.unitValue)
    } else {
      return this.unitValue + number
    }
  }
}
