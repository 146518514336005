document.addEventListener("turbolinks:load", event => {
  let checkoutButton = document.querySelector("#stripe-checkout-button")
  if (checkoutButton == null) return

  const stripePublishableKey = document.querySelector('meta[name="stripe-publishable-key"]')['content']
  const stripeAccount = document.querySelector('meta[name="stripe-account"]')['content']
  let stripe = Stripe(stripePublishableKey, { stripeAccount: stripeAccount })

  checkoutButton.addEventListener("click", function () {
    this.disabled = true

    fetch("/stripe/sessions", {
      method: "POST",
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')['content']
      }
    })
      .then(response => {
        return response.json()
      })
      .then(session => {
        return stripe.redirectToCheckout({ sessionId: session.id })
      })
      .then(result => {
        // If redirectToCheckout fails due to a browser or network
        // error, you should display the localized error message to your
        // customer using error.message.
        if (result.error) {
          this.disabled = false
          alert(result.error.message)
        }
      })
      .catch(error => {
        this.disabled = false
        console.error("Error:", error)
      })
  })
})
