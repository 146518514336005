import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "formWrapper", "formInputs" ]
  static values = { url: String, fieldsWrapperSelector: String }

  updateForm(event) {
    const value = event.target.value

    if (value == "") {
      this.hide()
    } else {
      this.fetchForm(value)
      this.show()
    }
  }

  fetchForm(value) {
    const url = this.urlValue.replace(/VALUE/g, value)

    fetch(url)
      .then(response => response.text())
      .then(html => {
        this.formInputsTarget.innerHTML = this.getFragment(html, this.fieldsWrapperSelectorValue)
      })
  }

  hide() {
    this.formWrapperTarget.classList.add("hidden")
    this.formInputsTarget.innerHTML = ""
  }

  show() {
    this.formWrapperTarget.classList.remove("hidden")
  }

  getFragment(html, querySelector) {
    const fragment = document
      .createRange()
      .createContextualFragment(html)
      .querySelector(querySelector)

    return fragment.innerHTML
  }
}
