import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { sessionId: String, baseUrl: String }

  connect() {
    this.url = this.baseUrlValue + '?order[stripe_session_id]=' + this.sessionIdValue

    document.querySelector("#loading-modal").classList.remove('hidden')
    this.load()
  }

  load() {
    fetch(this.url, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')['content']
      }
    })
      .then(response => {
        document.querySelector("#loading-modal").classList.add('hidden')

        if (response.redirected) {
          Turbolinks.clearCache()
          document.getElementById("cart-count").innerHTML = "0"
          Turbolinks.visit(response.url, { action: "replace" })
        } else {
          alert("Something went wrong. We have not charged your payment source")
        }
      })
  }
}
