import ChannelBase from "./channel_base"

class OrdersChannel extends ChannelBase {
  static counterClass = ".badge.pending"
  static ordersClass = ".admin.orders #items.pending"
  static noRecordsClass = "#empty-queue-info"

  received({count, partial, id}) {
    this.updateCounter(count)
    this.updateOrders(partial, id)
    this.playSound()
  }

  updateCounter(count) {
    const badge = document.querySelector(this.constructor.counterClass)
    if (badge) {
      badge.innerHTML = count
      badge.classList.remove("hidden")
    }
  }

  updateOrders(partial, id) {
    const orders = document.querySelector(this.constructor.ordersClass)
    if (!orders) return
    if (orders.querySelector("#order-" + id)) return

    document.querySelector(this.constructor.noRecordsClass).classList.add("hidden")
    orders.insertAdjacentHTML('beforeend', partial)
  }

  playSound() {
    // NOTICE: no audio element, because of Turbolinks issues
    // and no reuse of the Audio object, because it didn't work consistently
    const audioPath = document.body.dataset.notificationSoundPath
    const audio = new Audio(audioPath)
    audio.play()
  }
}

document.addEventListener("turbolinks:load", event => {
  new OrdersChannel("OrdersChannel")
})
