document.addEventListener("turbolinks:load", event => {
  const paypalButtonId = "#paypal-button-container"
  if (!document.querySelector(paypalButtonId)) return

  paypal.Buttons({
    style: {
      color: "black"
    },
    fundingSource: paypal.FUNDING.PAYPAL,
    createOrder: function (data, actions) {
      return fetch('/paypal/orders', {
        method: 'POST',
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')['content']
        }
      }).then(response => {
        return response.json();
      }).then(data => {
        return data.id
      })
    },
    onApprove: function (data, actions) {
      console.log(data)
      document.querySelector("#loading-modal").classList.remove('hidden')
      const url = document.querySelector(paypalButtonId).dataset.url

      return fetch(url + '?order[paypal_order_id]=' + data.orderID, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')['content']
        }
      }).then(response => {
        document.querySelector("#loading-modal").classList.add('hidden')

        if (response.redirected) {
          Turbolinks.clearCache()
          document.getElementById("cart-count").innerHTML = "0"
          Turbolinks.visit(response.url, { action: "replace" })
        } else {
          alert("Something went wrong. We did not process your payment")
        }
      })
    }
  }).render(paypalButtonId);
})
