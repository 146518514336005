import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["links", "template"]

  connect() {
    this.wrapperClass = this.data.get("wrapperClass") || "nested-fields"
    this.hideMarkedAsDestroyed()
  }

  addItem(event) {
    event.preventDefault()

    let content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().getTime())
    this.linksTarget.insertAdjacentHTML('beforebegin', content)
  }

  removeItem(event) {
    let item = event.target.closest("." + this.wrapperClass)

    if (item.dataset.newRecord !== undefined) {
      item.remove()
    } else {
      item.querySelector("input[name*='_destroy']").value = 1
      item.style.display = 'none'
    }
  }

  hideMarkedAsDestroyed() {
    const items = this.element.querySelectorAll("." + this.wrapperClass)
    
    items.forEach((item) => {
      if (item.dataset.destroyed !== undefined) {
        item.style.display = 'none'
      }
    })
  }
}
