import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  static classes = [ "handle" ]
  static values = { modelName: String, attributeName: String }

  connect() {
    const handleClass = this.hasHandleClass ? this.handleClass : "move"
    this.attributeNameValue = this.attributeNameValue || "position"

    this.sortable = Sortable.create(this.element, {
      group: 'shared',
      animation: 150,
      cursor: 'move',
      handle: '.' + handleClass,
      onUpdate: this.update.bind(this)
    })
  }

  update(event) {
    const position = event.newIndex + 1

    fetch(event.item.dataset.updateUrl, {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json, text/javascript',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')['content']
      },
      body: JSON.stringify(this.position_params(position))
    })
  }

  position_params(position) {
    if (this.hasModelNameValue) {
      return { [this.modelNameValue]: { [this.attributeNameValue]: position } }
    } else {
      return {[this.attributeNameValue]: position}
    }
  }
}
